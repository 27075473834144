//
// = Download Block Styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_download_area {
	position: relative;
  overflow: hidden;
  z-index: 0;
}
.st_download_content_waraper {
	padding: 150px 0 90px;
	h2 {
	  font-weight: 300;
	  margin: 0 0 50px;
	  line-height: 1.7;
	}
  @include media-max(MD) {
    padding-top: 100px;
    padding-bottom: 55px;
  }
  @include media-max(SM) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.st_download_single_img {
	position: relative;
  z-index: 1;
}
.st_download_area .st_single_shape_01 {
  background: -moz-linear-gradient(left, rgba(91, 135, 255, 0.66) 0%, rgba(30, 105, 230, 0.66) 90%, rgba(23, 102, 227, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(91, 135, 255, 0.66) 0%, rgba(30, 105, 230, 0.66) 90%, rgba(23, 102, 227, 0) 100%);
  background: linear-gradient(to right, rgba(91, 135, 255, 0.66) 0%, rgba(30, 105, 230, 0.66) 90%, rgba(23, 102, 227, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a85b87ff', endColorstr='#001766e3',GradientType=1 );
  width: 700px;
  height: 110px;
  position: absolute;
  top: 35%;
  transform: rotate(42deg);
  left: -145px;
  z-index: -1;
}
.st_download_area .st_single_shape_02 {
  background: -moz-linear-gradient(left, rgba(5, 197, 241, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%);
  background: -webkit-linear-gradient(left, rgba(5, 197, 241, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%);
  background: linear-gradient(to right, rgba(5, 197, 241, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4005c5f1', endColorstr='#40ffffff',GradientType=1 );
  width: 700px;
  height: 110px;
  position: absolute;
  top: 35%;
  transform: rotate(-47deg);
  right: -162px;
  z-index: -1;
}


// download app 
.st_app_download_warap {
  padding: 215px 0;
  .st_app_download_title {
    margin-bottom: 55px;
    p {
      font-size: 1.5em;
      margin-top: 20px;
    }
  }
  .st_app_download_subscription {
    margin-bottom: 32px;
    .st_subscribe.st_subscribe_flat {
      &::before {
        opacity: 0;
      }
      input[type="email"] {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
      }
      input[type="submit"] {
        box-shadow: 0 5px 35px rgba(56, 209, 106, 0.5);
      }
    }
    .st_subscribe_info {
      p {
        color: #C3C3C7;
        font-size: .75em;
        margin-top: 20px;
      }
    }
  }
  .st_or {
    font-weight: bold;
    font-size: 1.125em;
    margin-bottom: 30px;
  }
  .st_app_download_btn {
    .st_default_btn {
      min-width: 200px;
      &:hover,
      &:focus {
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.22);
      }
    }
  }
}


.download_area_v2 {
  &::before {
    background-image: url(../images/v6/feature-bg.png);
    content: "";
    position: absolute;
    width: 100%;
    height: 300px;
    top: -293px;
    left: 0;
    background-repeat: repeat;
    background-size: auto;
    background-position: bottom center;
  }
}
.st_select_warap {
  display: inline-block;
  position: relative;
  margin-right: 25px;
  z-index: 0;
  &::after {
    width: 7px;
    height: 7px;
    position: absolute;
    left: inherit;
    top: 50%;
    content: "";
    border-bottom: 2px solid #F23F79;
    border-right: 2px solid #F23F79;
    display: block;
    right: 30px;
    transform: rotate(45deg);
    margin-top: -4px;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    color: $color-primary;
    font-size: 0.9em;
    padding: 12px 60px 12px 25px;
    border: none;
    background-color: $color-white;
    box-shadow: 0 0 0 4px rgba(255,255,255,0.15), 0 5px 6px rgba(0,0,0,0.1);
    cursor: pointer;
    @include transition();
    border-radius: 50px;
    min-height: 45px;
  }
  @include media-max(XS) {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
    select {
      width: 100%;
    }

  }
}