//
// = Subscription Block Styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_news_letter_area {
  text-align: center;
  position: relative;
  .st_subscribe_waraper {
	  background-size: 100% 100%;
	  background-repeat: no-repeat;
	  padding: 140px 0;
	  h2 {
	  	margin: 0 0 40px;
	  }
	}
}
.st_subscribe {
  position: relative;
  width: 554px;
  max-width: 100%;
  margin: 0 auto;
  i {
		font-size: 1.125em;
		left: 43px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		@include media-max(XS) {
			left: 30px;
		  top: 35px;
		}
  }
  input[type="email"] {
	  width: 100%;
	  height: 70px;
	  padding: 0 80px;
	  border: 1px solid #F2EFFF;
	  border-radius: 50px;
	  @include transition();
	  @include media-max(XS) {
	  	padding: 0 30px 0 70px;
	  }
	}
	input[type="submit"] {
		border-radius: 50px;
		position: absolute;
		border: none;
		height: 54px;
		text-transform: uppercase;
		font-weight: 600px;
		color: $color-white;
		padding: 0 40px;
		right: 8px;
		top: 8px;
		cursor: pointer;
		box-shadow: 0 5px 30px rgba(0, 0, 0, 0.16);
		@include transition();
		@include media-max(XS) {
			position: relative;
			width: 100%;
			right: inherit;
			top: inherit;
		}
	}
}
.st_subscription_info {
  margin-top: 11px;
  position: absolute;
  bottom: -36px;
  width: 100%;
  display: none;
  text-align: center;
}

.st_news_left_item {
	h2 {
		color: $color-polar;
		font-weight: 300;
		margin: 0 0 20px;
		font-size: 2.125em;
		@include media-max(MD) {
			@include font-size(28);
		}
		@include media-max(SM) {
			@include font-size(22);
		}
	}
  @include media-max(MD) {
	width: 100%;
  }
}
.st_subscribe {
	.st-subscription-form {
		.st_subscribe_email.st_subscribe_email_transparent[type="email"] {
			background-color: transparent;
			width: 100%;
			height: 60px;
			padding: 0 40px;
			border: 2px solid rgba($color-white, 0.1);
			color: $color-white;
		}
		input[type="submit"] {
			top: 0;
			right: 0;
			height: 60px;
		}
	}
	@include media-max(MD) {
		width: 80%;
	}
	@include media-max(SM) {
		width: 100%;
	}
}
.st_subscribe_info_icon {
  font-size: 0.875em;
  margin-top: 20px;
  i {
	  margin-right: 10px;
	}
	p {
		display: inline-block;
	}
}

// footer subscription wrap
.st_subscription_wrapper {
	> h2 {
		font-size: 2.750em;
		margin-bottom: 75px;
		@include media-max(LG) {
			@include font-size(38);
		}
		@include media-max(MD) {
			@include font-size(34);
		}
		@include media-max(SM) {
			@include font-size(28);
			margin-bottom: 50px;
		}
	}
}
.st_subscribe.st_subscribe_flat {
	width: 470px;
	max-width: 100%;
	margin: 0 auto;
	z-index: 0;
	&::before {
		border-radius: 5px;
		z-index: -1;
		width: calc(100% + 10px);
		height: 70px;
		position: absolute;
		left: 50%;
		top: 50%;
		content: "";
		transform: translate(-50%, -50%);
		background-color: rgba(255, 255, 255, 0.25);
		@include media-max(SM) {
			display: none;
		}
	}
	input[type="email"] {
	  background-color: $color-white;
	  width: 100%;
	  height: 60px;
	  padding: 0 40px;
	  border: 2px solid $color-white;
	  border-radius: 5px;
	}
	input[type="submit"] {
		border-radius: 5px;
		background: $color-gigas;
		color: $color-white;
		box-shadow: 0 5px 35px rgba(128, 102, 220, 0.5);
		top: 3px;
		right: 3px;
		padding: 0 25px;
		font-weight: 600;
		@include media-max(XS) {
			right: 0;
		}
	}
}

.st_subscribe.st_subscribe_flat.st_about_subscription{
	&:before{
		background-color: rgba(255, 255, 255, 0.25);
	}
}
.st_subscribe.st_subscribe_flat.st_about_subscription{
	 input[type="submit"] {
		box-shadow: 0 5px 35px rgba(0, 227, 246, 0.5);
		padding: 0 30px;
	}
} 
.st_subscribe.st_subscribe_flat.st_coming_soon{
	&:before{
		background-color: rgba(0, 0, 0, .05);
	}
}
.st_subscribe.st_subscribe_flat.st_coming_soon{
	 input[type="submit"] {
		box-shadow: 0 5px 35px rgba(30, 67, 221, 0.3);
		padding: 0 30px;
	}
}
