//
// = Login Block Styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_user_login_area{
	background-size: 90% 100%;
	background-repeat: no-repeat;
	background-position: bottom center;
	.st_user_login{
		width: 35%;
		margin: 0 auto;
		.st_input_item{
			background-color: $color-polar;
			padding:20px 24px;
			margin-bottom: 30px;
			border-radius: 5px;
			label{
				display: block;
				color: #333333;
				font-size: .75em;
			}
			input,
			textarea{
				padding: 0;
				border: none;
				color: #BEC3CC;
				width: 100%;
				background-color: transparent;
				box-shadow: none;
			}
		}
		.st_input_submit{
			input[type="submit"]{
				box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
			}
		}
	}
	@include media-max(MD) {
		.st_user_login{
			width: 90%;
		}
	}
	@include media-max(SM) {
		.st_user_login{
			width: 100%;
		}
	}
}

.st_user_login_wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.st_input_column {
		 flex: 0 0 46%;
	}
	@include media-max(SM) {
		-webkit-display: block;	
		display: block;	
	}
}

.st_user_login.st_user_login_v2 {
	width: 75%;
	@include media-max(MD) {
		width: 100%;
	}
}