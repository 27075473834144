
//
// = Font Setup
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */

$font-bootstrap:        -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-body:             "Poppins", sans-serif;
$font-title:            "Oxygen", "Poppins", sans-serif;
$font-quote:            Georgia, Verdana, Roboto, Serif;
$font-icon-etline:      'et-line';
$font-icon-fontawesome: 'FontAwesome';




//
// = Colors Setup
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */

// colors list
$color-gigas:            #5040A0 !default;
$color-perison-blue:     #1E43DD !default;
$color-aqua:             #00E3F6 !default;
$color-shamrock:         #3AD984 !default;
$color-yellow-orange:    #FFAA3B !default;
$color-pink:             #F23F79 !default;
$color-orange:           #FF5D1C !default;
$color-science-blue:     #0366D6 !default;
$color-emerald:          #38D16A !default;
$color-chambray:         #3B3E99 !default; // similar to gigas
$color-medium-purple:    #8066DC !default; // similar to gigas
$color-lochmara:         #0079BF !default; // trello + paypal
$color-froly:            #F46F6C !default; // gmail
$color-alpha-froly:      rgba($color-froly, 0.1)   !default; // gmail
$color-sweet-pink:       #FFA3A1 !default; // similar to froly // agency
$color-koamaru:          #1D1068 !default; // similar to gigas // variation 3
$color-haiti:            #140F30 !default; // title color
$color-magnolia:         #F9F8FF !default; // similar to f1f1f1 for background
$color-alice-blue:       #F5FBFF !default; // similar to f5f5f5 for background
$color-polar:            #F5F9FD !default; // similar to alice-blue/f5f5f5 for background
$color-primary:          #9595A8 !default;
$color-white:            #FFFFFF !default;
$color-black:            #000000 !default;
$color-semi-black:       #707070 !default;
$color-st-fog:           #D6CCFF !default;
$color-st-gray:          #999999 !default;
$color-st-concrete:      #F3F3F3 !default;
$color-zircon:           #F8FAFF !default;
$color-st-violet:        #D52EEF !default;
$color-sun-glow:         #FCCC2C !default;
$color-cabaret:          #D1386C !default;
$color-shamrock:         #26CB98 !default;
$color-clear-day:        #F9FFFD !default;
$color-snuff:            #DBD7E9 !default;
$color-french-grey:      #C3C3C7 !default;
$color-ebony-clay:       #1F2833 !default;
$color-black-rock:       #080327 !default;
$color-lavender:         #B4B5D5 !default;
$color-titan-white:      #F5F5FF !default;
$color-regent-gray:      #8D9BA4 !default;
$color-shhamrock:        #40D1A7 !default;


// Text colors
$color-title:            $color-haiti !default;
$color-subtitle:         $color-primary !default;
$color-text:             $color-primary !default;

// Social media
$color-facebook:         #3b5998 !default;
$color-google-plus:      #dd4b39 !default;
$color-twitter:          #00aced !default;
$color-linkedin:         #007bb6 !default;
$color-pinterest:        #cb2027 !default;
$color-git:              #666666 !default;
$color-tumblr:           #32506d !default;
$color-vimeo:            #aad450 !default;
$color-youtube:          #bb0000 !default;
$color-flickr:           #ff0084 !default;
$color-reddit:           #ff4500 !default;
$color-dribbble:         #ea4c89 !default;
$color-skype:            #00aff0 !default;
$color-instagram:        #517fa4 !default;
$color-lastfm:           #c3000d !default;
$color-behance:          #1769ff !default;
$color-rss:              #f26522 !default;
$color-blue-bell:        #9D9ECE !default;


// Breakpoint values for min-width operators.
$breakpoints : (
  'xl',
  'lg',
  'md',
  'sm',
  'xs'
);

// Font sizes, w/ optional line-heights for each font size.
// Null is mobile, no breakpoint.
$font_sizes: (
  10 : 'null',
  12 : 'null',
  13 : 'null',
  14 : 'null',
  15 : 'null',
  16 : 'null',
  18 : 'null',
  20 : 'null',
  22 : 'null',
  24 : 'null',
  25 : 'null',
  28 : 'null',
  30 : 1.7,
  34 : 1.6,
  38 : 1.5,
  40 : 1.4,
  44 : 1.4,
  48 : 1,
  52 : 1,
  55 : 1,
  60 : 1,
  78 : 0.9
);

// alignment for text-align
$alignments : (
  center,
  right,
  left
);

// aligs for justify-content, align-items
$aligns : (
  start,
  end,
  center
);


// width list
$wlists : (
  10%,
  20%,
  25%,
  30%,
  35%,
  40%,
  45%,
  50%,
  55%,
  60%,
  70%,
  75%,
  80%,
  85%,
  90%,
  95%,
  100%
);


// overlay opacity
$overlays : (
  'none',
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9
);

// display list
$displays : (
  none,
  inline,
  inline-block,
  block,
  table,
  table-row,
  table-cell,
  flex,
  inline-flex
);


// lineheight preset list
$lineheights:(
  '1',
  '1-1',
  '1-2',
  '1-3',
  '1-4',
  '1-5',
  '1-6',
  '1-7',
  '1-8',
  '1-9',
  '2',
  '2-1',
  '2-2',
  '2-3',
  '2-5',
  '3',
  '4'
);

//
// = Colors Mapping
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
$colors: (
  gigas:             $color-gigas,
  perison-blue:      $color-perison-blue,
  st-aqua:           $color-aqua,
  shamrock:          $color-shamrock,
  yellow-orange:     $color-yellow-orange,
  st-pink:           $color-pink,
  st-orange:         $color-orange,
  science-blue:      $color-science-blue,
  emerald:           $color-emerald,
  chambray:          $color-chambray,
  medium-purple:     $color-medium-purple,
  lochmara:          $color-lochmara,
  froly:             $color-froly,
  alpha-froly:       $color-alpha-froly,
  sweet-pink:        $color-sweet-pink,
  koamaru:           $color-koamaru,
  haiti:             $color-haiti,
  magnolia:          $color-magnolia,
  alice-blue:        $color-alice-blue,
  polar:             $color-polar,
  st-primary:        $color-primary,
  st-white:          $color-white,
  st-black:          $color-black,
  st-fog:            $color-st-fog,
  st-gray:           $color-st-gray,
  st-concrete:       $color-st-concrete,
  st-violet:         $color-st-violet,
  sun-glow:          $color-sun-glow,
  st-cabaret:        $color-cabaret,
  st-zircon:         $color-zircon,
  clear-day:         $color-clear-day,
  st-shamrock:       $color-shamrock,
  blue-bell:         $color-blue-bell,
  snuff:             $color-snuff,
  french-grey:       $color-french-grey,
  ebony-clay:        $color-ebony-clay,
  black-rock:        $color-black-rock,
  st-lavender:       $color-lavender,
  titan-white:       $color-titan-white,
  regent:            $color-regent-gray,
  shhamrock:         $color-shhamrock,
);


$social-colors: (
  facebook:    $color-facebook,
  google:      $color-google-plus,
  gplus:       $color-google-plus,
  google-plus: $color-google-plus,
  twitter:     $color-twitter,
  linkedin:    $color-linkedin,
  pinterest:   $color-pinterest,
  git:         $color-git,
  tumblr:      $color-tumblr,
  vimeo:       $color-vimeo,
  youtube:     $color-youtube,
  flickr:      $color-flickr,
  reddit:      $color-reddit,
  dribbble:    $color-dribbble,
  skype:       $color-skype,
  instagram:   $color-instagram,
  lastfm:      $color-lastfm,
  behance:     $color-behance,
  rss:         $color-rss,
);


$bg-size : (
  100-100 : 100% 100%,
  100-auto : 100% auto,
  auto-100 : auto 100%,
  auto : auto,
  cover: cover,
  contain: contain,
  initial: initial,
  inherit: inherit
);

$bg-repeat: (
  repeat: repeat,
  repeat-x: repeat-x,
  repeat-y: repeat-y,
  no-repeat: no-repeat,
  initial: initial,
  inherit: inherit
);

$bg-position: (
  left-top      : left top,
  left-center   : left center,
  left-bottom   : left bottom,
  right-top     : right top,
  right-center  : right center,
  right-bottom  : right bottom,
  center-top    : center top,
  center-center : center center,
  center-bottom : center bottom,
  initial: initial,
  inherit: inherit
);

$bg-attachment: (
  scroll: scroll,
  fixed: fixed,
  local: local,
  initial: initial,
  inherit: inherit
);

$overflow: (
  visible: visible,
  hidden : hidden,
  scroll : scroll,
  auto : auto,
  initial: initial,
  inherit: inherit
);



$st-perspective: 2000px;
$st-animation-duration: 0.4s;